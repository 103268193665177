<template>
  <b-card
    :title="currentUser.email"
  >
    <div class="row mb-1">
      <div class="col-12 col-md-6 col-lg-4">
        <b-form-group
          label="Имя"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="name"
            v-validate="'required'"
            name="name"
          />
          <small
            v-show="errors.has('name')"
            class="text-danger"
          >{{ errors.first('name') }}</small>
        </b-form-group>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-12 col-md-6 col-lg-4">
        <b-form-group
          label="Фото"
          label-for="photo"
        >
          <input-file
            v-model="profile_image_key"
            size="lg"
          />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <input-password
          v-model="current_password"
          label="Текущий пароль"
          name="current_password"
          :state="current_password === '' ? null : !errors.has('current_password')"
          :error="errors.first('current_password')"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <input-password
          v-model="password"
          v-validate="'min:8'"
          data-vv-as="password"
          label="Новый пароль"
          name="password"
          :state="password === '' ? null : !errors.has('password')"
          :error="errors.first('password')"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <b-button
          variant="primary"
          @click.prevent="onSave"
        >
          Обновить
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import InputFile from '@core/components/input/InputFile.vue'

import Auth from '@/api/http/models/auth/Auth'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    InputFile,
  },

  data() {
    return {
      name: '',
      profile_image_key: '',
      current_password: '',
      password: '',
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
    }),
  },

  created() {
    this.name = this.currentUser.name
    this.profile_image_key = this.currentUser.profile_image_key
  },

  methods: {
    ...mapActions({
      fetchCurrentUser: 'user/FETCH_CURRENT_USER',
    }),

    async onSave() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const payload = {
          name: this.name,
          profile_image_key: this.profile_image_key,
        }

        if (this.current_password && this.password) {
          payload.current_password = this.current_password
          payload.password = this.password
        }

        const response = await Auth.update(payload)

        if (response.status === 'success') {
          await this.fetchCurrentUser()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Аккаунт обновлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Произошла ошибка',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })

          this.errors.add({
            field: 'current_password',
            msg: 'Неверный пароль',
          })
        }
      }
    },
  },
}
</script>
